import React from "react"
import * as THREE from "three"

/*
  ThreeRendering:
  Responsible for all the three.js rendering throughout
  the website. Loads within a canvas div in App.js.

  TODO:
  - The canvas that is rendered is in pixels. This makes
  zooming possible, which it shouldn't be.
*/

class ThreeCanvas extends React.Component {
  componentDidMount() {
    var container
    var camera, scene, renderer, parentTransform
    var radius = 100,
      theta = 0

    var lineColor = 0x000000

    container = document.createElement("div")
    document.body.appendChild(container)

    camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      1,
      10000
    )
    scene = new THREE.Scene()

    var lineGeometry = new THREE.BufferGeometry()
    var points = []
    var point = new THREE.Vector3()
    var direction = new THREE.Vector3()
    for (var i = 0; i < 100; i++) {
      direction.x += 1 - 0.5
      direction.y += 1 - 0.5
      direction.z += 1 - 0.5
      direction.normalize().multiplyScalar(100)
      point.add(direction)
      points.push(point.x, point.y, point.z)
    }
    lineGeometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(points, 3)
    )
    parentTransform = new THREE.Object3D()
    parentTransform.position.x = Math.random() * 40 - 20
    parentTransform.position.y = Math.random() * 40 - 20
    parentTransform.position.z = Math.random() * 40 - 20
    parentTransform.rotation.x = Math.random() * 2 * Math.PI
    parentTransform.rotation.y = Math.random() * 2 * Math.PI
    parentTransform.rotation.z = Math.random() * 2 * Math.PI
    parentTransform.scale.x = Math.random() + 0.5
    parentTransform.scale.y = Math.random() + 0.5
    parentTransform.scale.z = Math.random() + 0.5
    for (var j = 0; j < 200; j++) {
      var object, material

      material = new THREE.LineBasicMaterial({
        color: lineColor,
      })

      object = new THREE.Line(lineGeometry, material)

      object.position.x = Math.random() * 400 - 200
      object.position.y = Math.random() * 400 - 200
      object.position.z = Math.random() * 400 - 200
      object.rotation.x = Math.random() * 2 * Math.PI
      object.rotation.y = Math.random() * 2 * Math.PI
      object.rotation.z = Math.random() * 2 * Math.PI
      object.scale.x = Math.random() + 0.5
      object.scale.y = Math.random() + 0.5
      object.scale.z = Math.random() + 0.5
      parentTransform.add(object)
    }
    scene.add(parentTransform)
    renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    })

    renderer.setPixelRatio(window.devicePixelRatio)

    renderer.setSize(window.innerWidth, window.innerHeight)

    this.mount.appendChild(renderer.domElement)

    var onWindowResize

    window.addEventListener("resize", onWindowResize, false)

    onWindowResize = function () {
      const width = renderer.domElement.innerWidth
      const height = renderer.domElement.innerHeight
      camera.aspect = width / height
      camera.updateProjectionMatrix()
      renderer.setSize(width, height)
    }

    var render = function () {
      theta += 0.02
      camera.position.x = radius * Math.sin(THREE.Math.degToRad(theta))
      camera.position.y = radius * Math.sin(THREE.Math.degToRad(theta))
      camera.position.z = radius * Math.cos(THREE.Math.degToRad(theta))
      camera.lookAt(scene.position)
      camera.updateMatrixWorld()
      // find intersections
      renderer.render(scene, camera)
    }

    var animate = function () {
      //setTimeout(function () {
      //  requestAnimationFrame(animate)
      //}, 16.66)
      render()
    }

    animate()
  }

  render() {
    return <div className="threeJSCanvas" ref={ref => (this.mount = ref)} />
  }
}

export default ThreeCanvas
