import React from "react"
import { Link } from "gatsby"
import Image from "../image"

import FacebookIcon from "../../images/social/facebook.png"
import InstagramIcon from "../../images/social/instagram.png"
import VimeoIcon from "../../images/social/vimeo.png"
import SoundCloudIcon from "../../images/social/soundcloud.png"

import ArchiveLogo from "../../images/archive.png"

import Style from "./header.module.scss"

/**
 * Header (Default): Header and menu for all pages
 */
const Header = () => (
    <div className={Style.headerContainer}>
        <header>
            <div className={Style.mainLinks}>
                <Link to="/">
                    {/** TODO: Rendered by the image.js file 
          from boilerplate for the timebeing */}
                    <Image />
                </Link>
                <ArchiveLink />
            </div>
            <NavMenu />
        </header>
    </div>
)

export default Header

/* ===================================================*/

/**
 * NavMenu: Full menu including social media links
 */
const NavMenu = () => {
    let menuItems = [
        { id: 0, href: "/about/", color: "#E1B330", name: "ABOUT" },
        { id: 1, href: "/events/", color: "#C85F3F", name: "EVENTS" },
        { id: 2, href: "/media/", color: "#97172E", name: "MEDIA" },
        { id: 3, href: "/albums/", color: "#A7238F", name: "ALBUMS" },
        { id: 4, href: "/press/", color: "#6733C2", name: "PRESS" },
        { id: 5, href: "/support/", color: "#3954AF", name: "SUPPORT" },
        { id: 6, href: "/contact/", color: "#5A975B", name: "CONTACT" },
    ]
    const Menu = menuItems.map(menuItem => {
        return (
            <li key={menuItem.id}>
                <StyledLink
                    href={menuItem.href}
                    color={menuItem.color}
                    name={menuItem.name}
                />
            </li>
        )
    })

    return (
        <nav>
            <ul>{Menu}</ul>
            <SocialMediaMenu />
        </nav>
    )
}

/**
 * StyledLink: Links to site pages
 */
const StyledLink = props => {
    const { href, color, name } = props
    return (
        <Link
            to={href}
            className={Style.navLink}
            style={{
                backgroundColor: color,
            }}
        >
            &nbsp;{name}&nbsp;
        </Link>
    )
}

/**
 * SocialMediaMenu: Links to social media pages
 */
const SocialMediaMenu = () => {
    return (
        <div className={Style.social}>
            <div className={Style.flextable}>
                <SocialMediaIcon
                    href={"https://www.facebook.com/wetinkensemble/"}
                    src={FacebookIcon}
                    alt={"Facebook"}
                />
                <SocialMediaIcon
                    href={"https://www.instagram.com/wetink.ensemble/?hl=en"}
                    src={InstagramIcon}
                    alt={"Instagram"}
                />
                <SocialMediaIcon
                    href={"https://vimeo.com/wetink"}
                    src={VimeoIcon}
                    alt={"Vimeo"}
                />
                <SocialMediaIcon
                    href={"https://soundcloud.com/wet-ink-ensemble"}
                    src={SoundCloudIcon}
                    alt={"SoundCloud"}
                />
            </div>
        </div>
    )
}

/**
 * SocialMediaIcon: Component for a single social media icon
 */
const SocialMediaIcon = props => {
    const { href, src, alt } = props
    return (
        <div>
            <a href={href} rel={"noopener noreferrer"} target={"_blank"}>
                <img src={src} className={Style.socialImg} alt={alt} />
            </a>
        </div>
    )
}

/**
 * ArchiveLink: Link to archive page
 */
const ArchiveLink = props => {
    const href = "http://archive.wetink.org/"
    const alt = "Wet Ink Archive"
    return (
        <div className={Style.archiveImg}>
            <a href={href} rel={"noopener noreferrer"} target={"_blank"}>
                <img src={ArchiveLogo} alt={alt} />
            </a>
        </div>
    )
}
